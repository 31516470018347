

















































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import MultiRoundDialogue from './MultiRoundDialogue.vue';
import MultiRoundDialogueSingleWindow from './MultiRoundDialogueSingleWindow.vue';

@Component({
    components: {
        MultiRoundDialogue,
        MultiRoundDialogueSingleWindow,
    },
})
export default class DynamicContent extends Vue {
    @Prop() caseData?: any;
    @Prop({ default: false })
    editMode: boolean;
    @Prop()
    contentConfig: any;
    @Prop({ default: () => ({}) })
    contentData: any;
    @Prop({ default: false })
    useDefaultContentData: boolean;
    defaultImg = '//s3plus.meituan.net/v1/mss_c9d411db85ec4b59aaebc4dfca416c55/production/search-nlp-fe/static/img/clogo.b681817.png';
    activeCollapseValue = [];

    show(img) {
        this.$viewerApi({
            images: img,
        });
    }
    filterContent(data) {
        return data.replace(/</g, '&lt').replace(/>/g, '&gt'); // 显示html标签代码片段
    }
    linkUrl(item) {
        let url: string = this.contentData[item.contentKey] || (this.useDefaultContentData ? item.contentKey : '');
        if (url.includes('//www.dianping.com/shop/') && !url.includes('utm_source')) {
            url = url.includes('?')
                ? url + '&utm_source=annotation&utm_medium=brain.meituan.com'
                : url + '?utm_source=annotation&utm_medium=brain.meituan.com';
        }
        // 如果是 ugc 详情页需要拼参数
        if (url.includes('/ugcdetail/')) {
            const token = localStorage.getItem('annotation_ssoid');
            url = `${url}${url.includes('?') ? '&' : '?'}ssotoken=${token}`;
        }
        if (url.includes('http://')) {
            url = url.replace('http://', 'https://');
        }
        // 样本未添加协议会导致跳转链接自动携带当前url
        if (url !== '' && !url.includes('https://')) url = 'https://' + url;
        return url;
    }

    $refs: {
        collapseFieldOpenRenderHtml: HTMLDivElement[];
        openRenderHtml: HTMLDivElement[];
        // multiRoundDialogue: MultiRoundDialogue[];
        // multiRoundDialogueSingleWindow: MultiRoundDialogueSingleWindow[];
    };

    getDialogueData() {
        // if (this.$refs.multiRoundDialogue) {
        //     const res = {};
        //     this.$refs.multiRoundDialogue.forEach((item, index) => {
        //         res[index] = item.transSaveData()[index];
        //     });
        //     return res;
        // }
        // if (this.$refs.multiRoundDialogueSingleWindow) {
        //     return this.$refs.multiRoundDialogueSingleWindow?.[0]?.transSaveData();
        // }
        return null;
    }

    get multiRoundDialogueData() {
        if (!this.caseData) {
            return {};
        }
        // 编辑标注显示标注结果
        if (
            this.caseData.annotationResult &&
            this.$route.name === 'annotation-work' &&
            this.caseData.annotationTimeLengthDetail.annotationTime
        ) {
            return JSON.parse(this.caseData.annotationResult).dialogueData;
        }

        // 标注界面有标注记录并且有质检记录 return 质检记录
        if (
            this.$route.name === 'annotation-work' &&
            this.caseData.suggestInfo &&
            JSON.parse(this.caseData.suggestInfo)?.annotationResultCheck &&
            !this.caseData.annotationTimeLengthDetail.annotationTime
        ) {
            const { annotationResultCheck } = JSON.parse(this.caseData.suggestInfo);
            return JSON.parse(annotationResultCheck).dialogueData;
        }
        // 标注界面有标注记录 return 标注记录
        // 其他界面 都return 标注记录
        if (this.caseData.annotationResult || (this.caseData.annotationResult && this.$route.name !== 'annotation-work')) {
            return JSON.parse(this.caseData.annotationResult).dialogueData;
        }
        // 标注界面无标注记录 return null
        return null;
    }

    @Watch('contentData', { immediate: true })
    changeContentItemList() {
        this.$nextTick(() => {
            this.$refs.collapseFieldOpenRenderHtml?.forEach((item) => {
                let shadowRoot = item.shadowRoot;
                if (!shadowRoot) {
                    shadowRoot = item?.attachShadow({ mode: 'open' });
                }
                if (!shadowRoot) {
                    return false;
                }
                shadowRoot.innerHTML = this.contentData[item.dataset.content_key] || '';
                const style = document.createElement('style');
                style.innerHTML = `*{pointer-events: none;}`;
                shadowRoot.appendChild(style);
            });
            this.$refs.openRenderHtml?.forEach((item) => {
                let shadowRoot = item.shadowRoot;
                if (!shadowRoot) {
                    shadowRoot = item?.attachShadow({ mode: 'open' });
                }
                if (!shadowRoot) {
                    return false;
                }
                shadowRoot.innerHTML = this.contentData[item.dataset.content_key] || '';
            });
        });
    }

    transModels(item) {
        if (!this.caseData) {
            return [];
        }
        const { contentKey, isDoubleBlind } = item;
        let models = this.contentData[contentKey] ? JSON.parse(this.contentData[contentKey]).models : [];
        if (isDoubleBlind === 1 && !this.multiRoundDialogueData) {
            models.sort(() => Math.random() - 0.5);
        }
        if (this.multiRoundDialogueData) {
            models.forEach((m, index) => {
                models[index] = this.multiRoundDialogueData[index].model;
            });
        }
        return models;
    }

    multiWindowData(contentKey) {
        return this.contentData[contentKey] ? JSON.parse(this.contentData[contentKey]) : {};
    }
}
