































import { Vue, Component } from 'vue-property-decorator';
import { CaseDatas } from '@/model/modules/admin';
import ContentArea from './content-area.vue';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import AnnotationRemarkInfo from '@/components/annotation-remark-info.vue';

@Component({
    components: {
        ContentArea,
        AnnotationRemarkInfo,
    },
})
export default class DynamicTemplate extends Vue {
    caseData: CaseDatas = null;
    saveBtnLoading = false;

    reasonStr = '';
    annotationFormData = null;
    suggestInfo = null;
    annotationResult = null;
    loopAnnotationFormData = null;

    cascaderIdsArrMap: any = {};

    remarkStrNew = {
        caseType: [],
        caseStr: '',
    };

    $refs: {
        contentArea: ContentArea;
    };

    get taskConfig() {
        if (typeof this.caseData.config === 'string') {
            return JSON.parse(this.caseData.config);
        } else {
            return this.caseData.config || {};
        }
    }

    get contentData() {
        return this.getContentDataByCaseData(this.caseData);
    }

    getContentDataByCaseData(caseData) {
        const contentData = caseData?.caseInfo?.contentCols;
        if (contentData) {
            return Object.keys(contentData).reduce((obj, key) => {
                const keys = key.split('.');
                if (keys.shift() === 'contentArea') {
                    keys.reduce((obj, item, index) => {
                        if (!obj[item]) {
                            obj[item] = {};
                        }
                        if (index === keys.length - 1) {
                            obj[item] = contentData[key];
                        }
                        return obj[item];
                    }, obj);
                }
                return obj;
            }, {});
        } else {
            return {};
        }
    }

    async checkData() {
        try {
            await Promise.all(this.$refs.contentArea.$refs.dynamicForms?.map((item) => item.validate()));
        } catch (e) {
            e && !e.hidden && this.$mtd.message.error('有未完成标注的表单项!');
            return false;
        }
        return true;
    }

    async getPrev() {
        const { data } = await this.$api.common.getPreviousCase({ taskId: this.caseData.taskId, currentCaseId: this.caseData.caseId });
        if (data) {
            this.caseData = data;
            this.dealCaseData();
        } else {
            this.initState();
        }
    }
    async onTempSave(customParams?) {
        const selCaseTypes = this.remarkStrNew.caseType || [];
        if (selCaseTypes.length === 0 && !(await this.checkData())) {
            return;
        }
        const result = this.getAnnotationData();
        const cascaderIdsArr = Object.keys(this.cascaderIdsArrMap).map((k) => this.cascaderIdsArrMap[k]);
        const finalResult = { 'contentArea.content.cascader.ids': cascaderIdsArr, ...result };
        this.annotationResult = { annotationData: finalResult };
        // 防止二次保存
        this.saveBtnLoading = true;
        try {
            const saveCaseInfo: any = {
                caseId: this.caseData.caseId,
                annotationResult: JSON.stringify(this.annotationResult),
                annoExtraType: JSON.stringify(this.remarkStrNew.caseType),
                annoExtraValue: this.remarkStrNew.caseStr,
            };
            const { rescode } = await this.$api.common.tempSaveCase(saveCaseInfo);
            if (rescode !== 0) {
                return false;
            }
            if (customParams?.jump) {
                customParams.jump();
            } else {
                // 获取下一条
                this.initState();
                const { taskId, caseId } = this.$route.query;
                await this.getNextCase(taskId, caseId);
                this.dealCaseData();
            }
        } catch (e) {
            console.log(e);
            // alert('保存错误');
            this.$mtd.message.error(`暂存失败`);
        } finally {
            this.saveBtnLoading = false;
        }
    }

    getAnnotationData() {
        const prefix = 'contentArea';
        return this.taskConfig.contentArea?.reduce(
            (obj, content) => {
                if (content.type === 'annotationForm') {
                    const id = `${prefix}.${content.id}`;
                    content.areaConfig?.formItemList.forEach((item) => {
                        let value = this.annotationFormData?.[content.id]?.[item.vModel];
                        if (isEmpty(value)) {
                            if (item.noAnnotationDefaultValue !== undefined) {
                                value = item.noAnnotationDefaultValue;
                            } else {
                                value = item.defaultValue;
                            }
                        }
                        if (value !== undefined && value !== null) {
                            obj[`${id}.${item.vModel}`] = value;
                        }
                    });
                }
                if (content.type === 'loopCols' && this.caseData.caseInfo.loopAreas) {
                    this.caseData.caseInfo.loopAreas.forEach((loopArea, loopAreaIndex) => {
                        let loopTmp = {};
                        content.content.forEach((item) => {
                            if (item.type === 'annotationForm') {
                                const id = `loop.${prefix}.${item.id}`;
                                item.areaConfig?.formItemList.forEach((loopItem) => {
                                    let value = this.loopAnnotationFormData[loopAreaIndex][item.id]?.[loopItem.vModel];
                                    if (isEmpty(value)) {
                                        if (
                                            loopItem.noAnnotationDefaultValue !== undefined &&
                                            loopItem.noAnnotationDefaultValue !== '' &&
                                            loopItem.noAnnotationDefaultValue !== null
                                        ) {
                                            value = loopItem.noAnnotationDefaultValue;
                                        } else {
                                            value = loopItem.defaultValue;
                                        }
                                    }
                                    if (value !== undefined && value !== null) {
                                        loopTmp[`${id}.${loopItem.vModel}`] = value;
                                    }
                                });
                            }
                        });
                        obj.loopAnnotationDatas.push(loopTmp);
                    });
                }
                return obj;
            },
            {
                loopAnnotationDatas: [],
            }
        );
    }

    async getCaseById(taskId, caseId) {
        try {
            this.saveBtnLoading = true;
            const { data } = await this.$api.common.getCaseById({ caseId: caseId });
            if (data) {
                this.caseData = data;
                this.dealCaseData();
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.saveBtnLoading = false;
        }
    }

    async getNextCase(taskId, caseId) {
        try {
            this.saveBtnLoading = true;
            const { data } = await this.$api.common.getNextCase({ taskId: taskId, currentCaseId: caseId || null });
            if (data) {
                this.caseData = data;
                this.dealCaseData();
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.saveBtnLoading = false;
        }
    }

    getAnnotationFormData(annotationResult = this.annotationResult) {
        const defaultAnnotationFormData =
            this.taskConfig.contentArea?.reduce((obj, content) => {
                if (content.type === 'annotationForm') {
                    obj[content.id] = {};
                }
                return obj;
            }, {}) || {};
        const annotationData = annotationResult?.annotationData;
        if (annotationData) {
            const annotationFormData = Object.keys(annotationData).reduce((obj, key) => {
                const keys = key.split('.');
                if (keys.shift() === 'contentArea') {
                    keys.reduce((obj, item, index) => {
                        if (!obj[item]) {
                            obj[item] = {};
                        }
                        if (index === keys.length - 1) {
                            obj[item] = annotationData[key];
                        }
                        return obj[item];
                    }, obj);
                }
                return obj;
            }, {});
            Object.assign(defaultAnnotationFormData, annotationFormData);
        }
        return defaultAnnotationFormData;
    }

    getLoopAnnotationFormData(annotationResult = this.annotationResult) {
        let defaultAnnotationFormData = [];
        this.caseData.caseInfo.loopAreas.forEach((loopNum) => {
            if (this.taskConfig.contentArea.find((i) => i.type == 'loopCols')) {
                let tempObj = {};
                this.taskConfig.contentArea
                    .find((i) => i.type == 'loopCols')
                    .content.forEach((item) => {
                        if (item.type === 'annotationForm') {
                            tempObj[item.id] = {};
                        }
                    });
                defaultAnnotationFormData.push(tempObj);
            }
        });
        const annotationData = annotationResult?.annotationData;
        if (annotationData) {
            const loopAnnotationDatas = annotationData.loopAnnotationDatas;
            let tempObj = {};
            loopAnnotationDatas.forEach((loopItem, loopIndex) => {
                const temp = Object.keys(loopItem).reduce((obj, key) => {
                    const keys = key.split('.');
                    const firstKey = keys.shift();
                    const secondKey = keys.shift();
                    if (firstKey === 'loop' && secondKey === 'contentArea') {
                        keys.reduce((obj, item, index) => {
                            if (!obj[item]) {
                                obj[item] = {};
                            }
                            if (index === keys.length - 1) {
                                obj[item] = loopItem[key];
                            }
                            return obj[item];
                        }, obj);
                    }
                    return obj;
                }, {});
                Object.keys(temp).forEach((content) => {
                    if (Object.hasOwnProperty.call(tempObj, content)) tempObj[content] = Object.assign(tempObj[content], temp[content]);
                    else tempObj[content] = temp[content];
                });
                defaultAnnotationFormData[loopIndex] = cloneDeep(tempObj);
            });
        }
        return defaultAnnotationFormData;
    }

    async dealCaseData() {
        let { suggestInfo, annotationResult, extra, remark } = this.caseData;

        if (suggestInfo) {
            suggestInfo = JSON.parse(suggestInfo);
            suggestInfo.annotationResultCheck =
                typeof suggestInfo.annotationResultCheck === 'string'
                    ? JSON.parse(suggestInfo.annotationResultCheck)
                    : suggestInfo.annotationResultCheck;
            this.suggestInfo = suggestInfo;
        }

        annotationResult &&
            (this.annotationResult = typeof annotationResult === 'string' ? JSON.parse(annotationResult) : annotationResult);
        this.remarkStrNew.caseType = extra ? JSON.parse(extra) : [];
        this.remarkStrNew.caseStr = remark ? remark : '';
        this.annotationFormData = this.getAnnotationFormData();
        //如果有循环标注区数据
        if (this.caseData.caseInfo.loopAreas && this.caseData.caseInfo.loopAreas.length) {
            this.loopAnnotationFormData = this.getLoopAnnotationFormData();
        }
    }

    onCascaderChange(params) {
        if (params.formDataId) {
            if (Array.isArray(params.selectedOptions[0])) {
                this.cascaderIdsArrMap[params.formDataId] = params.selectedOptions
                    .map((selectedOption) => selectedOption[selectedOption.length - 1]?.id)
                    .filter((id) => id);
            } else {
                this.cascaderIdsArrMap[params.formDataId] = [params.selectedOptions[params.selectedOptions.length - 1]?.id].filter(
                    (id) => id
                );
            }
        }
    }

    saveAndExit() {
        const customParams = {
            jump: () => {
                this.$router.push({ path: '/task-list' });
            },
        };
        this.onTempSave(customParams);
    }

    initState() {
        this.reasonStr = '';
        this.caseData = null;
        this.suggestInfo = null;
        this.annotationResult = null;
        this.annotationFormData = null;
        this.loopAnnotationFormData = null;
        this.remarkStrNew.caseType = [];
        this.remarkStrNew.caseStr = '';
    }

    async mounted() {
        this.initState();
        let { taskId, caseId } = this.$route.query;
        if (!taskId) {
            this.$mtd.message.error('您还没有选择要标注的任务，请先选择标注任务');
            Vue.nextTick(() => {
                this.$router.push('/task-list');
            });
            return void 0;
        }
        if (caseId) {
            await this.getCaseById(taskId, caseId);
        } else {
            await this.getNextCase(taskId, caseId);
        }
        this.annotationFormData = this.getAnnotationFormData();
    }
}
