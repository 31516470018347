import axios from 'axios';
import { getAuthHeaders } from './header';
import queryString from 'query-string';

const authHeader = getAuthHeaders();
const customHeader = {
    'Content-Type': 'application/json',
    'X-NlpOpen-User': 'wb_muyingjie',
    'X-NlpOpen-Token': '49b3d1b9-0d84-4a0c-8453-5e3c228d5482',
};
const urlMap = {
    test: {
        modelUrl: 'https://model.inf.test.sankuai.com',
        url: 'https://annotation.inf.test.sankuai.com',
    },
    staging: {
        modelUrl: 'https://model.inf.st.sankuai.com',
        url: 'https://llmmark.sankuai.com',
    },
    production: {
        modelUrl: 'https://model.sankuai.com',
        url: 'https://brain.meituan.com',
    },
};

const userMis = window.localStorage.getItem('annotation_name');

export async function getListWorker(modelId: number) {
    const queryObj = {
        modelId,
    };
    const qs = queryString.stringify(queryObj);
    // const env = process.env.CONFIG_ENV || 'test';
    const env = 'production';

    // return await axios.post(
    //     `${urlMap[env].url}/query/annotation/common/v1/httpGetProxy`,
    //     { url: `${urlMap[env].modelUrl}/external/chat/listWorker?${qs}`, headers: customHeader },
    //     { headers: authHeader }
    // );
    return await axios.get(`${urlMap[env].modelUrl}/external/chat/listWorker?${qs}`, {
        // headers: { ...authHeader, 'LLM-External-User': userMis, 'LLM-External-Source': 'CMark-Chat' }
        headers: authHeader,
    });
}

export async function getChatGenerate(params: any, progressCallBack: (e: any) => void) {
    // const env = process.env.CONFIG_ENV || 'test';
    const env = 'production';

    return await axios.post(
        `${urlMap[env].modelUrl}/external/chat/generate`,
        { ...params },
        {
            // headers: { ...authHeader, 'LLM-External-User': userMis, 'LLM-External-Source': 'CMark-Chat' },
            headers: authHeader,
            responseType: 'stream',
            onDownloadProgress: progressCallBack,
        }
    );
}
