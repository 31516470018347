import { render, staticRenderFns } from "./content-area.vue?vue&type=template&id=24ad960c&scoped=true&"
import script from "./content-area.vue?vue&type=script&lang=ts&"
export * from "./content-area.vue?vue&type=script&lang=ts&"
import style0 from "./content-area.vue?vue&type=style&index=0&id=24ad960c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ad960c",
  null
  
)

export default component.exports